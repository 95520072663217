/* src/index.css or App.css */
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Roboto' -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  font-family: 'Poppins';
}
.about{
  background: url(../public/images/services.jpeg);
  background-size: cover;
  background-position: center;
}

.careers{
  background: url(../public/images/careers.jpg);
  background-size: cover;
  background-position: center;
}
.legal{
  background: url(../public/images/containerbest.jpg);
  
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.check3{
  background: url(../public/images/surveys.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}
.heli{
  background: url(../public/images/heli.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

}
.collab{
  background: url(../public/images/collab.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

}
.broking{
  background: url(../public/images/containerbest.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

}
.ship{
  background: url(../public/images/contact.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

}
.nqt{
  background: url(../public/images/nqt.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

}
.highlight{
 
    background-color: oklch(23.2607% 0.013807 253.100675 / 1); /* Choose your color */
    padding: 0.2em 0.4em; /* Optional padding for better appearance */
    border-radius: 4px; /* Optional rounded corners */
    display: inline-block;
    border-radius: 20px;
    color: white;
    text-align: center;
  }
.services{
  background: url(../public/images/aboutcheck.jpg);
  background-size: cover;
  background-position: center;
}
.hero{
  background: url(../public/images/heroo.jpg);
  background-size: cover;
  background-position: center;
}

.contact{background: url(../public/images/pipeline.jpg);
  background-size: cover;
  background-position: center;
}

.glasscontainer{
  background: rgba(245, 245, 245, 0.263);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  box-shadow: -7px 10px 40px 5px black;

}
.glasscontainerhead{
  background: rgba(245, 245, 245, 0.263);
  backdrop-filter: blur(2px);
  border-radius: 30px;
  /* box-shadow: -7px 10px 40px 5px black; */
  padding: 12px;

}
.glasscontainerservices{
  backdrop-filter: blur(15px);
}
.partners
{
  background: rgba(245, 245, 245, 0.263);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  box-shadow: -7px 10px 40px 5px black;
  padding: 40px;


}